import * as CONFIG from '@/config/config.json'

const BACKEND_ADDRESS = `${CONFIG.http_protocol + CONFIG.backend_host + (CONFIG.backend_port ? `:${CONFIG.backend_port}` : '') + CONFIG.backend_path}`

function APIResponseVacancyAdapter (vacancy) {
  const arrDetails = []
  if (vacancy.details) {
    vacancy.details.forEach(item => {
      const objDetail =
      {
        strHeading: item.headings,
        strText: item.texts,
        arrPoints: item.points,
      }
      arrDetails.push(objDetail)
    })
  }

   const objVacancy = {
    strID: vacancy.hashedId,
    strTitle: vacancy.title,
    strDescription: vacancy.description,
    arrDetails: arrDetails,
    tDatePosted: new Date(vacancy.datePosted),
    tDateClosing: new Date(vacancy.dateClosing),
  }
  return objVacancy
}

export default {
  name: 'APIRequests',

  data: () => ({
    g_arrVacancies: [],
    g_objSelectedVacancy: {},
    g_bCareerApplicationSubmitting: false,
    g_bCareerApplicationSubmitted: false,
    g_bContactUsEmailSubmitted: false,
    g_bCareerApplicationSuccess: false,
  }),

  methods: {
    getVacancyList () {
      window.axios.get(`${BACKEND_ADDRESS}/vacancy/hashed/`)
        .then((res) => {
          const arrVacancies = []
          res.data.forEach(vacancy => {
            const objVacancy = APIResponseVacancyAdapter(vacancy)
            arrVacancies.push(objVacancy)
          })

          this.g_arrVacancies = arrVacancies
        }).catch((err) => {
          console.error(err)
        })
    },

    getVacancyByID (strHashedID) {
      window.axios.get(`${BACKEND_ADDRESS}/vacancy/hashed/${strHashedID}`)
        .then((res) => {
          const objVacancy = APIResponseVacancyAdapter(res.data)

          this.g_objSelectedVacancy = objVacancy
        }).catch((err) => {
          console.error(err)
        })
    },

    // Modified 16/07/2020 by Nico to return Boolean ( Apply Success or Fail)
    postApplication (strHashedID, strName, strEmail, strMessage, objCV) {
      const data = new FormData()
      data.append('idVacancy', strHashedID)
      data.append('name', strName)
      data.append('email', strEmail)
      data.append('messages', strMessage)
      data.append('cv-data', objCV)

      const vacancyID = strHashedID

      this.g_bCareerApplicationSubmitting = true

      window.axios.post(`${BACKEND_ADDRESS}/applicant/hashed/${vacancyID}`, data)
        .then((res) => {
          console.log(res)

          this.g_bCareerApplicationSuccess = res.status === 200 || res.status === 201
        }).catch((err) => {
          console.error(err)
          this.g_bCareerApplicationSuccess = false
        }).then(() => {
          this.g_bCareerApplicationSubmitting = false
          this.g_bCareerApplicationSubmitted = true
        })
    },

    postEmail (strName, strEmail, strSubject, strMessage) {
      const data = new FormData()
      data.append('name', strName)
      data.append('from_email', strEmail)
      data.append('subject', strSubject)
      data.append('message', strMessage)

      window.axios.post(`${BACKEND_ADDRESS}/mail/send`, data)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.g_bContactUsEmailSubmitted = true
          }
        }).catch((err) => {
          console.error(err)
        })
    },
  },
}
