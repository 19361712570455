<template>
  <v-footer id="home-subscribe" :color="strBackgroundColor" style="height: 100vh; overflow: hidden">
    <div style="position: absolute; top: 0%; left: 0%; right: 0%; bottom: 0%">
      <icon-subs-top-left style="position: absolute; top: 0%; left: 0%" :color="hexBgColor" />
      <icon-subs-top-right
        :style="{ position: 'absolute', top: ' 0%', right: g_bLowestBr ? '0%' : '5%' }"
        :color="hexBgColor"
      />
      <icon-subs-bottom-left
        :style="{ position: 'absolute', bottom: '-1%', left: g_bLowestBr ? '0%' : '5%' }"
        :color="hexBgColor"
      />
      <icon-subs-bottom-right
        style="position: absolute; bottom: -1%; right: 0%"
        :color="hexBgColor"
      />
    </div>
    <v-container class="main-bw-400--text fill-height" style="position: relative">
      <v-row>
        <v-col
          :cols="g_bLowerBr || g_bBaseBr ? 12 : 9"
          style="width: fit-content; margin: auto; height: fit-content"
        >
          <div :class="`kda-ts-${g_bLowerBr ? 32 : 48}pt wt-extrabold textTitle`">
            {{ m_strTextTitle }}
          </div>
          <div :class="`kda-ts-${g_bLowerBr ? 16 : 20}pt nunito wt-regular textInfo mt-4 ln-160`">
            {{ m_strTextInfo }}
          </div>
          <div class="row mt-50">
            <v-form ref="m_formElement" :disabled="bSubmitting" class="col-md-5 py-0 mx-0">
              <base-text-field
                :label="m_strLabelEmail"
                outlined
                required
                color="black"
                email
                @change="changeEmail($event)"
              />
            </v-form>
            <v-btn
              color="main-r-400"
              class="kda-ts-16pt nunito wt-bold textSubscribe mx-3"
              style="color: white"
              :disabled="bSubmitting"
              @click="subscribe()"
            >
              {{ m_strButtonLabel }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <k-d-a-submitted-modal
      v-model="g_bContactUsEmailSubmitted"
      :str-title="m_objSubmittedMessage.strTitle"
      :str-text="m_objSubmittedMessage.strText"
      text-color="black"
      @input="toggleModal($event)"
    />
  </v-footer>
</template>

<script>
import APIRequests from '@/mixins/api-requests';
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDASubscribe',

  components: {
    IconSubsTopLeft: () => import('@/components/base/icons/IconSubsTopLeft'),
    IconSubsTopRight: () => import('@/components/base/icons/IconSubsTopRight'),
    IconSubsBottomLeft: () => import('@/components/base/icons/IconSubsBottomLeft'),
    IconSubsBottomRight: () => import('@/components/base/icons/IconSubsBottomRight'),
    KDASubmittedModal: () => import('@/components/KDASubmittedModal')
  },

  mixins: [APIRequests, BaseBreakpoint],

  props: {
    iconColor: {
      type: String,
      default: '#DAFFE5'
    },
    strBackgroundColor: {
      type: String,
      default: '#FFF'
    },
    strButtonLabel: {
      type: String,
      default: 'Berlangganan'
    },
    bSubmitting: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    m_objSubmittedMessage: {
      strTitle: 'Thank you for subscription!',
      strText: `You'll be sent the next newspaper from us shortly`
    },
    m_strAnonymousName: ' ',
    m_strAnonymousEmail: '',
    m_strAnonymousSubject: ' ',
    m_strAnonymousMessage: ' '
  }),

  computed: {
    m_strTextTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.common.subscribe.header');
    },
    m_strTextInfo() {
      return this.$vuetify.lang.t('$vuetify.kda.common.subscribe.content');
    },
    m_strButtonLabel() {
      return this.$vuetify.lang.t('$vuetify.kda.common.subscribe.subscribe');
    },
    m_strLabelEmail() {
      return this.$vuetify.lang.t('$vuetify.kda.consult.form.input4');
    },
    hexBgColor() {
      return this.$route?.meta?.hexBookColor;
    }
  },
  methods: {
    changeEmail(anonymousEmail) {
      if (anonymousEmail) {
        this.m_strAnonymousEmail = anonymousEmail;
      }
    },
    subscribe() {
      window.gtag('event', 'click', {
        'event_label': 'Subscribe'
      });

      const isValid = this.$refs.m_formElement.validate();
      if (isValid) {
        this.postEmail(
          this.m_strAnonymousName,
          this.m_strAnonymousEmail,
          this.m_strAnonymousSubject,
          this.m_strAnonymousMessage
        );
      }
    },
    toggleModal(bShow) {
      this.$router.go();
    }
  }
};
</script>

<style scoped lang="scss">
.mt-50 {
  margin-top: 50px;
}

.contentCenter {
  justify-content: center;
  align-items: center;
  background-color: aquamarine;
}
.textTitle {
  width: 100%;
}
.textInfo {
  width: 70%;
}
.textInput {
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Nunito;
}
.textSubscribe {
  text-transform: capitalize;
  border-radius: 5px !important;
}
</style>
